import React from "react";
import { NavLink, Routes } from "react-router-dom";
import { Route } from "react-router-dom";
import "./BlogCardFeatured.css";
import BlogDetail from "./BlogDetail";
// import { useNavigate } from "react-router-dom";

const BlogCardFeatured = (props) => {
  const { id, title, preview, description, image, imagePNG, keywords } =
    props.blog;
  return (
    <div className="BlogCardFeatured">
      <div className="BlogCardFeatured-title-description">
        <h1>{title}</h1>
        <p>{preview}</p>
        <div className="BlogCardFeatured-button-container">
          <NavLink to={"/blog/" + id}>
            <button>Read More</button>
          </NavLink>
        </div>
      </div>
      <div className="BlogCardFeatured-image">
        <img src={imagePNG}></img>
      </div>
    </div>
  );
};

export default BlogCardFeatured;
