import React from "react";

import IntroSection from "../../Sections/IntroSection";
import OurWork from "../../Sections/OurWork";
import SectionHeader from "../../Components/SectionHeader";
import ServiceSection from "../../Sections/ServicesSection";
import TestimonialsSection from "../../Sections/TestimonialsSection";
import CTACard from "../../Components/CTACard";
import WelcomeSection from "../../Sections/WelcomeSection";
import WelcomeHeader from "../../Sections/WelcomeHeader";

const Home = () => {
  return (
    <div>
      <div className="WelcomeSection-container">
        {/* <WelcomeSection></WelcomeSection> */}
        <WelcomeHeader></WelcomeHeader>
      </div>
      <div className="Intro-section-container">
        <IntroSection></IntroSection>
      </div>
      <div className="SectionHeader-container">
        <SectionHeader
          title="Our Showcase"
          subtitle="Explore our past projects showcasing creativity and expertise."
        ></SectionHeader>
      </div>
      <div className="OurWork-section-container">
        {/* <h2>Our Work</h2> */}
        <OurWork></OurWork>
      </div>
      <div className="SectionHeader-container">
        <SectionHeader
          title="A picture is a poem without words"
          subtitle="Let us help you build and enhance your brand’s image with stunning visuals"
        ></SectionHeader>
      </div>
      <div className="Services-section-container">
        <ServiceSection></ServiceSection>
      </div>
      {/* <div className="SectionHeader-container">
        <SectionHeader
          title="Testimonials"
          subtitle="What our clients say about us"
        ></SectionHeader>
      </div>
      <div className="TestimonialSection-container">
        <TestimonialsSection></TestimonialsSection>
      </div> */}
      <div className="CTACard-container">
        <CTACard
          title="Get your quotation"
          subtitle="You can call us and get your quotation, or whats app on the same number"
          buttonTitle="Call us"
          navigationTo="tel:+91 9599972261"
        ></CTACard>
      </div>
    </div>
  );
};

export default Home;
