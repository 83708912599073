import React from "react";
import "./About.css";
import aboutImage from "../../ImageAssets/creativeShoot.jpg";

const About = () => {
  return (
    <div className="About">
      {/* <img src={aboutImage} alt="About Image" className="about-image"></img> */}
      <div className="text-gradient">
        <h1>About Tech Focus Studios</h1>
        <p>
          Welcome to Tech Focus Studios, where we specialize in transforming
          visual narratives through the art of photography, product videography,
          and Digital Media for advertising. We're not just a photography firm;
          we're storytellers dedicated to creating captivating imagery that
          speaks volumes about your products and brand, leaving a lasting
          impression on your audience.
        </p>
        <br></br>
        <p>
          At Tech Focus Studios, we recognize the power of visuals in today's
          visually driven world. Whether you're a budding entrepreneur, an
          established brand, or a creative visionary, our team of skilled
          photographers, videographers, and digital media experts is here to
          capture the essence of your products, ideas, and visions.
        </p>
        <br></br>
        <p>
          Our services go beyond traditional photoshoots. We offer comprehensive
          solutions, including product photography, model studio sessions,
          product videography to showcase your offerings in dynamic ways, and
          Digital Media for advertising that ensures your brand stands out in
          the digital landscape. Our studio serves as a creative canvas where
          your ideas come to life, and our tools, including cameras and digital
          media platforms, help paint your narrative vividly. Our commitment to
          excellence, attention to detail, and staying ahead of industry trends
          make us the ideal partner for businesses and individuals looking to
          elevate their visual and digital presence.
        </p>
        <br></br>
        <p>
          Let Tech Focus Studios be your partner in bringing your ideas to life,
          turning them into captivating visual and digital stories that resonate
          deeply with your audience. Join us on a journey where every frame,
          whether in photography or digital media, tells a unique story and
          unlocks boundless creativity. Tech Focus Studios: where every visual
          element articulates a narrative, and where creativity knows no bounds.
        </p>
        <br></br>
        {/* <p>
          At Tech Focus Studios, we specialize in providing dynamic photography
          solutions that help build and enhance your brand’s image. Our team has
          expertise in creative shoots that capture every detail with precision
          and creativity, lifestyle shoots that embody your brand’s spirit and
          essence, table top shoots that are perfect for product photography,
          model shoots that showcase your products in the best way possible, 360
          degree video shoots that bring your products to life, mannequin shoots
          and many more. At Tech Focus Studios, we believe in endless learning
          and innovating to keep up with the fast-paced world of photography.
          Get in touch to discuss your next project.
        </p> */}
      </div>
    </div>
  );
};

export default About;
