import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./NavigationBar.css";
import horizontalLogo from "../ImageAssets/logo_horizontal_TFS3.png";

const NavigationBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <nav>
      <Link
        to="/"
        className="title"
        onClick={() => {
          setIsMenuOpen(false);
        }}
      >
        Tech Focus Studios
      </Link>
      <Link
        to="/"
        className="image"
        onClick={() => {
          setIsMenuOpen(false);
        }}
      >
        <img src={horizontalLogo} className="horizontalLogo" alt="logo" />
      </Link>
      <div
        className="navMenu"
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
        }}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={isMenuOpen ? "open" : ""}>
        <li>
          <NavLink
            to="/"
            onClick={() => {
              setIsMenuOpen(false);
            }}
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/about"
            onClick={() => {
              setIsMenuOpen(false);
            }}
          >
            About
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/portfolio"
            onClick={() => {
              setIsMenuOpen(false);
            }}
          >
            Portfolio
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/blog"
            onClick={() => {
              setIsMenuOpen(false);
            }}
          >
            Blog
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/contact"
            onClick={() => {
              setIsMenuOpen(false);
            }}
          >
            Contact
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default NavigationBar;
