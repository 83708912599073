import React from "react";
import "./TestimonialSection.css"

const TestimonialCard = (props) => {
    const {title, testimonialText, image} = props.testimonial
    return <div className="TestimonialCard">
        <img className="TestimonialCard-image" src={image} alt={title}></img>
        <div className="TestimonialCard-title-description">
            {/* <h2 className="TestimonialCard-title">{title}</h2> */}
            <p className="TestimonialCard-paragraph">{testimonialText}</p>
            <h4 className="TestimonialCard-title">- {title}</h4>
        </div>
        
    </div>
}


export default TestimonialCard;