import React from "react";
import "./BlogDetail.css";
import blogs from "./BlogsData";

import featuredBlogImage from "../../ImageAssets/creativeShoot.jpg";
import { useParams } from "react-router-dom";

const BlogDetail = (props) => {
  const { id } = useParams();
  console.log(id);
  console.log(blogs[0]);
  const foundBlog = findBlogById(blogs, id);
  console.log(foundBlog);
  return (
    <div className="BlogDetail">
      <div className="BlogDetail-top-container">
        <h6>
          {foundBlog.type} • {foundBlog.date}
        </h6>
        <h1>{foundBlog.title}</h1>
        <p>{foundBlog.preview}</p>
      </div>
      <img src={foundBlog.image}></img>
      <div className="BlogDetail-middle-container">
        {foundBlog.description()}
        {/* <ProductPhotographyGuide></ProductPhotographyGuide> */}
      </div>
    </div>
  );
};

export default BlogDetail;

const findBlogById = (blogs, id) => {
  return blogs.find((blog) => blog.id === id);
};

// const ProductPhotographyGuide = () => {
//   return (
//     <div className="article">
//       <p>
//         In the bustling world of e-commerce, where attention spans are short and
//         competition is fierce, the importance of captivating product photography
//         cannot be overstated. A visually appealing product image has the power
//         to stop scrolling thumbs, pique curiosity, and ultimately drive
//         purchasing decisions. In this comprehensive guide, we'll delve into the
//         essential tips and techniques to help you master the art of product
//         photography and elevate your online presence.
//       </p>
//       <br />

//       <h2>1. Understanding Your Audience:</h2>
//       <p>
//         Before diving into the technical aspects of product photography, it's
//         crucial to understand your target audience and the visual language that
//         resonates with them. Are they drawn to minimalist aesthetics, vibrant
//         colors, or natural lighting? Conducting market research and analyzing
//         competitor visuals can provide valuable insights into the preferences of
//         your audience, helping you tailor your photography style accordingly.
//       </p>
//       <br />

//       <h2>2. Investing in Quality Equipment:</h2>
//       <p>
//         While you don't necessarily need the latest and most expensive gear to
//         capture stunning product images, investing in quality equipment can
//         significantly enhance the final result. A DSLR or mirrorless camera with
//         a variety of lenses gives you greater control over depth of field and
//         focal length, allowing you to achieve sharp focus and creative
//         compositions. Additionally, investing in a sturdy tripod, diffused
//         lighting setup, and a selection of props can further elevate your
//         photography game.
//       </p>
//       <br />

//       <h2>3. Mastering Lighting Techniques:</h2>
//       <p>
//         Lighting is perhaps the most critical aspect of product photography, as
//         it directly impacts the mood, tone, and clarity of your images. Whether
//         you opt for natural light or artificial lighting setups, mastering
//         lighting techniques is essential for achieving professional-looking
//         results. Experiment with different lighting angles, diffusers, and
//         reflectors to minimize harsh shadows, control highlights, and create a
//         soft, even illumination that accentuates your product's features.
//       </p>
//       <br />

//       <h2>4. Perfecting Composition and Styling:</h2>
//       <p>
//         Composition plays a vital role in guiding the viewer's eye and conveying
//         the desired message or emotion. When framing your product shots,
//         consider factors such as the rule of thirds, leading lines, and negative
//         space to create visually balanced and engaging compositions.
//         Additionally, pay attention to styling elements such as background
//         choice, props, and product placement to enhance the overall aesthetic
//         and tell a compelling visual story.
//       </p>
//       <br />

//       <h2>5. Editing for Enhancement:</h2>
//       <p>
//         While capturing high-quality images straight out of the camera is ideal,
//         post-processing can further enhance the visual appeal of your product
//         photos. Software tools like Adobe Photoshop and Lightroom offer a wide
//         range of editing capabilities, from adjusting exposure and color balance
//         to removing blemishes and imperfections. However, it's essential to
//         exercise restraint and avoid over-editing, as authenticity and
//         transparency are key in building trust with your audience.
//       </p>
//       <br />

//       <h2>6. Optimizing for E-commerce:</h2>
//       <p>
//         In the context of e-commerce, where product images serve as the primary
//         point of contact between consumers and brands, optimizing your
//         photography for online platforms is paramount. Utilize high-resolution
//         images that allow for zooming and provide multiple angles to give
//         customers a comprehensive view of your products. Additionally, adhere to
//         platform-specific guidelines regarding image dimensions, file formats,
//         and background requirements to ensure consistency and professionalism
//         across your product catalog.
//       </p>
//       <br />

//       <h2>7. Testing and Iterating:</h2>
//       <p>
//         As with any aspect of digital marketing, continuous testing and
//         iteration are essential for refining your product photography strategy
//         and maximizing its effectiveness. Monitor key metrics such as
//         click-through rates, conversion rates, and bounce rates to gauge the
//         performance of your product images and identify areas for improvement.
//         Experiment with different photography styles, layouts, and messaging to
//         see what resonates best with your audience and drives the desired
//         actions.
//       </p>
//       <br />

//       <p>
//         In conclusion, mastering the art of product photography requires a
//         combination of technical skill, creativity, and a deep understanding of
//         your target audience. By implementing the tips and techniques outlined
//         in this guide, you can create compelling product images that captivate
//         viewers, drive engagement, and ultimately, boost sales and revenue for
//         your e-commerce business. Remember to stay curious, experiment boldly,
//         and never stop striving for photographic excellence.
//       </p>
//     </div>
//   );
// };

// const blogs = [
//   {
//     id: "5789",
//     title:
//       "Mastering the Art of Product Photography: Essential Tips and Techniques",

//     keywords: [
//       "Product Photography",
//       "Tips",
//       "Techniques",
//       "Visual Marketing",
//       "E-commerce",
//       "Photography Lighting",
//     ],

//     preview:
//       "In the competitive world of e-commerce, captivating product photography can make all the difference in attracting customers and driving sales. In this article, we'll explore essential tips and techniques to help you master the art of product photography. From lighting and composition to styling and editing, learn how to create stunning visuals that showcase your products in the best possible light and leave a lasting impression on your audience.",
//     image: imageBlog1,
//     imagePNG: imageBlog2PNG,
//     description: ProductPhotographyGuide,
//   },
//   {
//     id: "5790",
//     title:
//       "The Power of Product Videography: Engaging Your Audience with Dynamic Visuals",

//     keywords: [
//       "Product Videography",
//       "Visual Content",
//       "Video Marketing",
//       "Engagement",
//       "Brand Storytelling",
//       "Motion Graphics",
//     ],

//     preview:
//       "In today's digital landscape, static images are no longer enough to capture the attention of consumers. Product videography offers a dynamic way to showcase your products and engage your audience on a deeper level. Discover the power of video marketing and learn how to leverage product videography to tell your brand story, demonstrate product features, and create memorable experiences that drive conversions and foster brand loyalty.",
//     image: imageBlog2,
//     imagePNG: imageBlog2PNG,
//     description: ProductPhotographyGuide,
//   },
//   {
//     id: "5791",
//     title:
//       "Unlocking Success with Digital Advertising: Strategies for Effective Campaigns",

//     keywords: [
//       "Digital Advertising",
//       "Campaign Strategy",
//       "PPC",
//       "Social Media Ads",
//       "Targeting",
//       "ROI",
//     ],

//     preview:
//       "Digital advertising has revolutionized the way businesses reach and engage with their target audience. In this article, we'll explore proven strategies for creating effective digital advertising campaigns that deliver measurable results. From pay-per-click (PPC) ads to social media advertising, learn how to define your target audience, craft compelling ad copy, and optimize your campaigns for maximum ROI.",
//     image: imageBlog3,
//     imagePNG: imageBlog2PNG,
//   },
//   {
//     id: "5792",
//     title: "Optimizing Your Product Photography for E-commerce Success",

//     keywords: [
//       "E-commerce Photography",
//       "Product Images",
//       "Online Retail",
//       "Image Optimization",
//       "Conversion Rate Optimization (CRO)",
//       "White Background Photography",
//     ],

//     preview:
//       "When it comes to selling products online, high-quality photography is essential for capturing the attention of potential customers and driving conversions. In this article, we'll share expert tips for optimizing your product photography for e-commerce success. Learn how to choose the right backgrounds, angles, and props, as well as how to optimize your images for fast loading times and seamless integration with your online store.",
//     image: imageBlog4,
//     imagePNG: imageBlog2PNG,
//   },
//   {
//     id: "5793",
//     title:
//       "Harnessing the Power of Social Media Advertising for Business Growth",

//     keywords: [
//       "Social Media Advertising",
//       "Facebook Ads",
//       "Instagram Ads",
//       "Targeting",
//       "Audience Insights",
//       "Brand Awareness",
//     ],

//     preview:
//       "Social media advertising offers unparalleled opportunities for businesses to connect with their target audience and drive meaningful engagement. In this article, we'll explore how to harness the power of social media advertising to fuel your business growth. From choosing the right platforms to targeting your audience effectively, learn how to create compelling ad campaigns that increase brand awareness, drive website traffic, and generate leads and sales.",
//     image: imageBlog5,
//     imagePNG: imageBlog2PNG,
//   },
// ];
