import React from "react";
import "./IntroSection.css";

import intro_image from "../ImageAssets/intro.png";
import intro_image2 from "../ImageAssets/creativeShoot.jpg";

import Carousel from "../Components/Carousel";

import firstShoot from "../ImageAssets/firstShoot.png";
import secondShoot from "../ImageAssets/secondShoot.png";
import thirdShoot from "../ImageAssets/thirdShoot.png";
import fourthShoot from "../ImageAssets/fourthShoot.png";

const images = [firstShoot, secondShoot, thirdShoot, fourthShoot];

const IntroSection = () => {
  return (
    <div className="Intro-section">
      <div className="Intro-section-image">
        {/* <Carousel  images={images} /> */}
        <img
          src={intro_image2}
          className="Intro-section-image"
          alt="introImage"
        />
      </div>
      <div className="Intro-section-content">
        <p>{introDescription}</p>
      </div>
    </div>
  );
};

const introDescription =
  "Welcome to Tech Focus Studios where we transform the art of photography into a captivating language that speaks volumes about your products and brand. We are not just a photography firm we are visual storytellers, dedicated to crafting images that resonate with your audience and leave a lasting impression. At Tech Focus Studios, we understand the power of a single image in today's visually driven world. Whether you're a budding entrepreneur, an established brand, or a creative visionary, our team of skilled photographers and artists is here to capture the essence of your products, ideas, and visions. Our services extend far beyond the confines of a traditional photoshoot. We provide comprehensive solutions, including product photography, model studio sessions, and a wide range of creative services to showcase your products in their best light. Our studio is a canvas where your ideas come to life, and our lenses are the brushes that paint your narrative. Our commitment to excellence, attention to detail, and dedication to staying at the forefront of photography trends make us the ideal choice for businesses and individuals looking to elevate their visual presence. Let Tech Focus Studios be your partner in bringing your ideas into focus and turning them into captivating visual stories that resonate with your audience. Join us on a journey where every click of the shutter is a step closer to perfection, where every image tells a unique story, and where your vision finds its truest expression. Tech Focus Studios, where every frame tells a story, and unlocks creativity.";

export default IntroSection;
