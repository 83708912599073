import React from "react";
import "./Blog.css";

import BlogCard from "./BlogCard";

import blogs from "./BlogsData";
import { NavLink } from "react-router-dom";
import BlogCardFeatured from "./BlogCardFeatured";

const Blog = (props) => {
  const featuredBlogIndex = 1;
  return (
    <div className="Blog">
      <div className="title">
        <h1>TFS Blog</h1>
      </div>
      <BlogCardFeatured blog={blogs[featuredBlogIndex]}></BlogCardFeatured>
      <div className="blog-section">
        {blogs.map((blog) => {
          return (
            <NavLink to={"/blog/" + blog.id}>
              <BlogCard blog={blog}></BlogCard>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
};

export default Blog;
