import React from "react";

const BlogCard = (props) => {
  // const {title, preview, image} = props.blog;
  return (
    <div className="blog-section-item">
      <img src={props.blog.image}></img>
      <div className="blog-section-item-title-description">
        <h2>{props.blog.title}</h2>
        <h1>
          {props.blog.keywords
            // .map((t) => <span>{t}</span>)
            .reduce((prev, curr) => [prev, ", ", curr])}
        </h1>
        <p>{props.blog.preview}</p>
      </div>
    </div>
  );
};

export default BlogCard;
