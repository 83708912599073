import React from "react";
import "./ServicesSection.css";

const ServiceCard = (props) => {
  const { title, description, image, type } = props.service;
  return (
    <div className="ServiceCard-section">
      {type === "image" ? (
        <img src={image} className="ServiceCardImage" alt="service name"></img>
      ) : (
        <video
          autoPlay
          loop
          muted
          playsInline
          controls={false}
          src={image}
          className="ServiceCardVideo"
          type="video/mp4"
        />
      )}
      <div className="ServiceCard-titleDescription">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default ServiceCard;
