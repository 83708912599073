import React, { useEffect, useState } from "react";
import "./WelcomeHeader.css";

import productFocus from "../ImageAssets/productFocus.png";
import productFocusHorizontal from "../ImageAssets/productFocusHorizontal.png";

import product1 from "../ImageAssets/ProductPNG/product1.png";
import product2 from "../ImageAssets/ProductPNG/Product2.png";
import product3 from "../ImageAssets/ProductPNG/Product3.png";
import product4 from "../ImageAssets/ProductPNG/Product4.png";
import product5 from "../ImageAssets/ProductPNG/Product5.png";
import product6 from "../ImageAssets/ProductPNG/Product6.png";

// , product4, product5, product6

const products = [product1, product2, product3];

function ImageSwapper() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      console.log("Logs every 5 Second:");
      if (currentIndex === products.length - 1) {
        console.log("Inside if");
        setCurrentIndex(0);
      } else {
        let index = currentIndex + 1;
        console.log("Inside else" + index);
        setCurrentIndex(index);
      }
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <img src={products[currentIndex]} className="WelcomeHeader-product" />
    </div>
  );
}

const WelcomeHeader = (props) => {
  return (
    <div className="WelcomeHeader">
      <div className="WelcomeHeader-info-frame">
        <div className="WelcomeHeader-info-content">
          <p>Welcome to</p>
          <h1>Tech Focus Studios</h1>
          <p>your go to destination for</p>
          <h2>Photography & Digital Advertising</h2>
        </div>
      </div>
      <div className="WelcomeHeader-product-frame">
        {/* {ImageSwapper()} */}
        <img
          src={product3}
          alt="product1"
          className="WelcomeHeader-product"
        ></img>
        {/* <img src={product2} alt="product2" className="WelcomeHeader-product2"></img>
                <img src={product3} alt="product3" className="WelcomeHeader-product3"></img> */}

        <picture>
          {/* <source srcset={productFocusHorizontal} media="(max-width: 1080px)"> */}
          <source
            media="(max-width: 568px)"
            srcSet={productFocusHorizontal}
            sizes="568px"
          />
          <img
            src={productFocus}
            alt="focus image"
            className="WelcomeHeader-product-focusBG"
          ></img>
        </picture>
      </div>
    </div>
  );
};

export default WelcomeHeader;
