import React from "react";
import "./OurWork.css";
import ImageHolder from "../Components/ImageHolder";

const OurWorkCard = (props) => {
    const {title, image, description} = props.work
    return (
        <div className="OurWorkCard-section">
            {/* <img src={image} className="OurWorkCard-image" alt= {{title} + "image"} /> */}
            <div className="OurWorkCard-image-container">
                <ImageHolder image={image}></ImageHolder>
            </div>
            <div className="OurWorkCard-title-description">
                <h2>{title}</h2>
                <p>{description}</p>
            </div>
        </div>
    );
}

export default OurWorkCard;