import React from "react";
import "./OurWork.css";
import OurWorkCard from "./OurWorkCard";
import firstShoot from "../ImageAssets/firstShoot.png";
import secondShoot from "../ImageAssets/secondShoot.png";
import thirdShoot from "../ImageAssets/thirdShoot.png";
import fourthShoot from "../ImageAssets/fourthShoot.png";
import { NavLink } from "react-router-dom";

const works = [
  {
    id: "1",
    title: "Creative Shoot : Oils and Essentials",
    image: firstShoot,
    description:
      "Our Creative Photo Shoot service is designed to showcase your product in the best possible way. We take great care in creatively placing your product into its ideal surroundings, while keeping the focus squarely on your brand. We’re dedicated to capturing the essence of your product, so that you can use these images to effectively market your goods.",
  },
  {
    id: "2",
    title: "Footwear For Zool",
    image: secondShoot,
    description:
      "Our Creative Photo Shoot service is designed to showcase your product in the best possible way. We take great care in creatively placing your product into its ideal surroundings, while keeping the focus squarely on your brand. We’re dedicated to capturing the essence of your product, so that you can use these images to effectively market your goods.",
  },
  {
    id: "3",
    title: "Creative Shoot : Jewellery",
    image: thirdShoot,
    description:
      "Our Creative Photo Shoot service is designed to showcase your product in the best possible way. We take great care in creatively placing your product into its ideal surroundings, while keeping the focus squarely on your brand. We’re dedicated to capturing the essence of your product, so that you can use these images to effectively market your goods.",
  },
  {
    id: "4",
    title: "Model Shoot : Jewellery",
    image: fourthShoot,
    description:
      "Our Creative Photo Shoot service is designed to showcase your product in the best possible way. We take great care in creatively placing your product into its ideal surroundings, while keeping the focus squarely on your brand. We’re dedicated to capturing the essence of your product, so that you can use these images to effectively market your goods.",
  },
];

const worksMap = works.map((work) => {
  return (
    <NavLink to="./portfolio">
      <OurWorkCard work={work}> </OurWorkCard>
    </NavLink>
  );
});

const OurWork = () => {
  return <div className="OurWork-section">{worksMap}</div>;
};

export default OurWork;
