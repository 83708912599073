// import logo from './logo.svg';
// import app_icon from './logo_TFS.png'
import app_icon_horizontal from "./ImageAssets/logo_horizontal_TFS3.png";
import app_icon from "./ImageAssets/logo_TFS.png";
// import NavigationBar from "./Sections/Navigation";

import firstShoot from "./ImageAssets/firstShoot.png";
import secondShoot from "./ImageAssets/secondShoot.png";

import "./App.css";
import NavigationBar from "./Components/NavigationBar";
import { Route, Routes } from "react-router-dom";
import Footer from "./Components/FooterView";
import About from "./Components/pages/About";
import Blog from "./Components/pages/Blog";
import Contact from "./Components/pages/Contact";
import Home from "./Components/pages/Home";
import BlogDetail from "./Components/pages/BlogDetail";
import Portfolio from "./Components/pages/Portfolio";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBoaoEj9jI9WBq7JiXk5b8Bs0eeM8GXgdU",
  authDomain: "tfs-website-8de22.firebaseapp.com",
  projectId: "tfs-website-8de22",
  storageBucket: "tfs-website-8de22.appspot.com",
  messagingSenderId: "759869046679",
  appId: "1:759869046679:web:3cf5986f934e05f5334ed2",
  measurementId: "G-MQL3EW04BC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Performance Monitoring and get a reference to the service
const perf = getPerformance(app);

function App() {
  return (
    <div className="App">
      {/* <div className="App-header">
        <div className='App-header-title-icon-container'>
          <img src={app_icon_horizontal} className='App-icon-horizontal' alt='logo' />
        </div>
        <h1 className="App-header-title">Tech Focus Studios</h1>
        <NavigationBar></NavigationBar>
      </div> */}
      <NavigationBar></NavigationBar>
      <Routes>
        <Route path="/" element={<Home></Home>}></Route>
        <Route path="/about" element={<About></About>}></Route>
        <Route path="/portfolio" element={<Portfolio></Portfolio>}></Route>
        <Route path="/blog" element={<Blog></Blog>}></Route>
        <Route path="/contact" element={<Contact></Contact>}></Route>
        <Route path="/blog/:id" element={<BlogDetail />}></Route>
      </Routes>
      <Footer></Footer>
    </div>
  );
}

export default App;
