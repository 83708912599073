import React from "react";
import "./ImageHolder.css";

const ImageHolder = (props) => {
    return (
        <div className="ImageHolder-container">
            <div className="ImageHolder-bgView">
                <img src={props.image} className="ImageHolder-image" alt="ImageHolderImage"></img>
            </div>
        </div>
    );
}

export default ImageHolder;