import React from "react";
import "./Portfolio.css";

import tfs_portfolio_1 from "../../ImageAssets/Tech Focus Studios Portfolio/TFS_Portfolio_1.png";
import tfs_portfolio_2 from "../../ImageAssets/Tech Focus Studios Portfolio/TFS_Portfolio_2.png";
import tfs_portfolio_3 from "../../ImageAssets/Tech Focus Studios Portfolio/TFS_Portfolio_3.png";
import tfs_portfolio_4 from "../../ImageAssets/Tech Focus Studios Portfolio/TFS_Portfolio_4.png";
import tfs_portfolio_5 from "../../ImageAssets/Tech Focus Studios Portfolio/TFS_Portfolio_5.png";
import tfs_portfolio_6 from "../../ImageAssets/Tech Focus Studios Portfolio/TFS_Portfolio_6.png";
import tfs_portfolio_7 from "../../ImageAssets/Tech Focus Studios Portfolio/TFS_Portfolio_7.png";
import tfs_portfolio_8 from "../../ImageAssets/Tech Focus Studios Portfolio/TFS_Portfolio_8.png";
import tfs_portfolio_9 from "../../ImageAssets/Tech Focus Studios Portfolio/TFS_Portfolio_9.png";
import tfs_portfolio_10 from "../../ImageAssets/Tech Focus Studios Portfolio/TFS_Portfolio_10.png";
import tfs_portfolio_11 from "../../ImageAssets/Tech Focus Studios Portfolio/TFS_Portfolio_11.png";

import portfolioPDF from "../../ImageAssets/TechFocusStudiosPortfolio.pdf";

const Portfolio = () => {
  return (
    <div className="portfolio-container">
      {/* <img src={tfs_portfolio_1} alt="TFS_Portfolio_1" /> */}
      {/* <img src={tfs_portfolio_2} alt="TFS_Portfolio_2" /> */}
      {/* <img src={tfs_portfolio_3} alt="TFS_Portfolio_3" /> */}
      <img src={tfs_portfolio_4} alt="TFS_Portfolio_4" />
      <img src={tfs_portfolio_5} alt="TFS_Portfolio_5" />
      <img src={tfs_portfolio_6} alt="TFS_Portfolio_6" />
      <img src={tfs_portfolio_7} alt="TFS_Portfolio_7" />
      <img src={tfs_portfolio_8} alt="TFS_Portfolio_8" />
      <img src={tfs_portfolio_9} alt="TFS_Portfolio_9" />
      <img src={tfs_portfolio_10} alt="TFS_Portfolio_10" />
      {/* <img src={tfs_portfolio_11} alt="TFS_Portfolio_11" /> */}
    </div>

    // <div className="portfolio-iframe-container">
    //   <iframe
    //     loading="lazy"
    //     src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFuaj2c7eI&#x2F;Mq6Q5qa88m5rh_s3xws0FA&#x2F;view?embed"
    //     allowfullscreen="allowfullscreen"
    //     allow="fullscreen"
    //     seamless="true"
    //   ></iframe>
    // </div>
  );
};

export default Portfolio;
