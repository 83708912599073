import React from "react";
import "./ServicesSection.css";

import flatShoot from "../ImageAssets/flatShoot.jpg";
import creativeShoot from "../ImageAssets/creativeShoot.jpg";
import shoot360Video from "../ImageAssets/shoot360Video.mp4";
import productDimensionShoot from "../ImageAssets/productDimensionShoot.jpg";
import ServiceCard from "./ServiceCard";

const services = [
    {    
        id: "1",
        title: "Flat Shoot",
        image: flatShoot,
        description: "Our Flat Shoot service is perfect for showcasing your products with clarity and precision. We'll capture 4-5 high-quality tabletop shots against a clean white background, allowing your products to take center stage. These images are ideal for e-commerce websites, catalogs, or any platform where you want to present your products in a professional and consistent manner.",
        type: "image",
    },
    {
        id: "2",
        title: "Creative/Lifestyle Shoot",
        image: creativeShoot,
        description:"Elevate your product presentation with our Creative/Lifestyle Shoot. We place your products in carefully curated and imaginative settings, creating a unique visual narrative for each item. With one captivating image per product, we infuse personality and context into your products, making them stand out and resonate with your target audience.",
        type: "image",

    },
    {
        id: "3",
        title: "360 Video Shoot",
        image: shoot360Video,
        description:"Bring your products to life with our immersive 360 Video Shoot. In a concise 15-second video clip, we'll showcase your product from every angle, rotating it a full 360 degrees. This dynamic presentation allows your customers to interact with your product virtually, gaining a comprehensive view of its design, features, and craftsmanship. It's the next best thing to holding the product in their hands.",
        type: "video",

    },
    {
        id: "4",
        title: "Product Dimensions Shoot",
        image: productDimensionShoot,
        description:"Accurate product dimensions are crucial for potential buyers. Our Product Dimensions Shoot provides customers with a clear understanding of the size and scale of your products. We'll photograph your items using an XYZ scale, highlighting their exact measurements. This service is particularly valuable for products where size matters, such as furniture, appliances, or any items where dimensions play a critical role in the buying decision.",
        type: "image",
    }
]

const servicesMap = services.map((service) => {
    return (
        <ServiceCard service = {service}> </ServiceCard>
    );
})

const ServiceSection = (props) => {
    return <div className="Service-section">
       {servicesMap}
    </div>
}

export default ServiceSection;