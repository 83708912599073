import React from "react";
import "./TestimonialSection.css"
import TestimonialCard from "./TestimonialCard"
import Testimonial1Image from "../ImageAssets/Testimonial1.JPG"
import Testimonial2Image from "../ImageAssets/Testimonial2.jpg"


const testimonials = [
    {
        title: "Divine Order Design",
        testimonialText: "\"Tech Focus Studios has been our go-to choice for product photography, and they consistently exceed our expectations. Their attention to detail and innovative approach to showcasing our products have significantly enhanced our brand's image. The quality of their work is outstanding, and they always meet our deadlines. We highly recommend Tech Focus Studios for top-notch product photography!\"",
        image: Testimonial1Image,
    },
    {
        title: "Furnishka",
        testimonialText: "\"Our experience with Tech Focus Studios for lifestyle and creative shoots has been nothing short of extraordinary. They possess a remarkable ability to translate concepts into visual stories that truly resonate. Their creativity knows no bounds, and the enthusiasm they bring to each shoot is infectious. We wholeheartedly recommend Tech Focus Studios to breathe life into your creative visions.\"",
        image: Testimonial2Image,
    }
]

const testimonialsMap = testimonials.map((testimonial) => {
    return (
        <TestimonialCard testimonial = {testimonial}></TestimonialCard>
    );
})

const TestimonialsSection = (props) => {
    return <div className="Testimonial-section">
       {testimonialsMap}
    </div>
}


export default TestimonialsSection;