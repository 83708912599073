import React from "react";
import "./CTACard.css";
import { NavLink } from "react-router-dom";

const CTACard = (props) => {
  return (
    <div className="CTACard">
      <h2>{props.title}</h2>
      <p>{props.subtitle}</p>
      <NavLink to={props.navigationTo}>
        <button className="CTAButton">{props.buttonTitle}</button>
      </NavLink>
    </div>
  );
};

export default CTACard;
