import React from "react";
import "./Contact.css";
import { NavLink } from "react-router-dom";
import ContactForm from "../ContactForm";
import CTACard from "../CTACard";

const Contact = () => {
  return (
    <div className="Contact">
      <div className="Contact-left-section">
        <div className="Contact-left-top-section">
          <h2>Get in touch today!</h2>
          <p>
            Email, call or fill out the form to learn how{" "}
            <b>Tech Focus Studios</b> can help you in your journey of brand
            building
          </p>
          <p>
            <NavLink to="mailto:info@techfocusstudios.com">
              info@techfocusstudios.com
            </NavLink>
          </p>
          <p>
            <NavLink to="tel:+91 9599972261">+91 9599972261</NavLink>
          </p>
        </div>
        <div className="Contact-left-bottom-section">
          <div className="Contact-left-bottom-section-item">
            <h2>Feedback & Suggestions</h2>
            <p>
              We value your feedback and are continiously working to improve our
              services, your input is crucial for Tech Focus Studios
            </p>
          </div>
          <div className="Contact-left-bottom-section-item">
            <h2>Career & Team</h2>
            <p>
              We are building our team, if you are good team player having
              skills like but not limited to Photography, Photo Editing,
              Videography, Video Editing, Graphic Designer, Motion Graphics,
              Studio Owner/Manager write to us at{" "}
              <NavLink to="mailto:info@techfocusstudios.com">
                info@techfocusstudios.com
              </NavLink>
            </p>
          </div>
        </div>
      </div>
      {/* <div className="Contact-right-section">
        <ContactForm></ContactForm>
      </div> */}
      <div className="CTACard-container">
        <CTACard
          title="Get your quotation"
          subtitle="You can call us and get your quotation, or whats app on the same number"
          buttonTitle="Call us"
          navigationTo="tel:+91 9599972261"
        ></CTACard>
      </div>
    </div>
  );
};

export default Contact;
